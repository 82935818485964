<template>
  <th-page-wrapper
    color="var(--font-color)"
    :actions="actions"
    @delete="handleDelete"
    @save="handleSubmit"
  >
    <!-- Form -->
    <questions-form ref="form" class="m-8" />
  </th-page-wrapper>
</template>

<script>
import QuestionsForm from './components/form'

export default {
  components: {
    QuestionsForm
  },

  computed: {
    isNew() {
      return !this.$route.params.id
    },
    actions() {
      return {
        permissionPrefix: 'products:service_questions',
        returnedPath: { name: 'products-service-questions-list' },
        isNew: this.isNew
      }
    }
  },

  methods: {
    // ----------------- Handle delete -----------------
    handleDelete() {
      this.$refs.form.handleDelete()
    },

    // ----------------- Handle submit -----------------
    handleSubmit() {
      this.$refs.form.handleSubmit()
    }
  }
}
</script>
