<template>
  <div class="mb-4">
    <el-form>
      <el-row :gutter="20">
        <!-- Minimum -->
        <el-col :xs="12" :sm="12" :md="8" :lg="6">
          <el-form-item
            :label="
              $t(
                'pages.product_service_questions.edit.form.properties.answer_validation.option.number_input_plus_optional_unit_select.properties.min.label'
              )
            "
          >
            <th-number-input
              :model-value="min"
              :locale="$i18n.locale"
              @update:modelValue="(v) => handleMinMaxInput(v, 'min')"
            />
          </el-form-item>
        </el-col>

        <!-- Maximum -->
        <el-col :xs="12" :sm="12" :md="8" :lg="6">
          <el-form-item
            :label="
              $t(
                'pages.product_service_questions.edit.form.properties.answer_validation.option.number_input_plus_optional_unit_select.properties.max.label'
              )
            "
          >
            <th-number-input
              :model-value="max"
              :locale="$i18n.locale"
              @update:modelValue="(v) => handleMinMaxInput(v, 'max')"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <!-- Units -->
      <th-input-title
        :title="
          $t(
            'pages.product_service_questions.edit.form.properties.answer_validation.option.number_input_plus_optional_unit_select.properties.units.label'
          )
        "
      />

      <el-row
        v-for="(option, index) in options"
        :key="index"
        :gutter="20"
        class="mb-3"
      >
        <!-- Unit -->
        <el-col :xs="18" :sm="12" :md="8" :lg="6">
          <el-input
            :model-value="option"
            data-testid="option-input"
            clearable
            @input="(v) => handleOptionInput(v, index)"
          />
        </el-col>

        <el-col :xs="6" :sm="12" :md="16" :lg="18">
          <!-- Add -->
          <el-button
            v-if="options.length - 1 === index"
            data-testid="add-button"
            plain
            class="el-button--primary-icon"
            icon="Plus"
            @click="addNew"
          />

          <!-- Delete -->
          <el-button
            v-else
            data-testid="delete-button"
            plain
            icon="Delete"
            class="el-button--text-icon"
            @click="removeItem(index)"
          />
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import safeGet from 'just-safe-get'
import safeSet from 'just-safe-set'

export default {
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },

  computed: {
    options: {
      get() {
        return this.parseOptions(this.modelValue)
      },
      set(v) {
        // no-op
      }
    },
    max: {
      get() {
        return safeGet(this.modelValue, 'properties.content.max')
      },
      set(v) {
        // no-op
      }
    },
    min: {
      get() {
        return safeGet(this.modelValue, 'properties.content.min')
      },
      set(v) {
        // no-op
      }
    }
  },

  methods: {
    parseOptions(obj) {
      const arr = []
      const _enum = safeGet(obj, 'properties.unit.enum')
      if (Array.isArray(_enum)) return _enum
      return arr
    },

    handleOptionInput(v, index) {
      const arr = [...this.options]
      arr[index] = v
      const result = {
        ...this.modelValue
      }
      safeSet(result, 'properties.unit.enum', arr)

      this.$emit('update:modelValue', result)
    },

    addNew() {
      const options = [...this.options, null]
      const result = {
        ...this.modelValue
      }
      safeSet(result, 'properties.unit.enum', options)
      this.$emit('update:modelValue', result)
    },

    removeItem(index) {
      this.options.splice(index, 1)
      const result = {
        ...this.modelValue
      }
      safeSet(result, 'properties.content.enum', this.options)
      this.$emit('update:modelValue', result)
    },

    handleMinMaxInput(v, prop) {
      if (!Number.isFinite(v)) {
        const result = {
          ...this.modelValue
        }
        safeSet(result, `properties.content.${prop}`, undefined)
        this.$emit('update:modelValue', result)
      } else {
        const result = {
          ...this.modelValue
        }
        safeSet(result, `properties.content.${prop}`, v)
        this.$emit('update:modelValue', result)
      }
    }
  }
}
</script>
