<template>
  <th-wrapper
    v-loading="loading"
    :title="$t('common.titles.general_info.title')"
    body-class="p-8 pb-4"
  >
    <template #center>
      <!-- Required -->
      <el-checkbox v-model="form.required">
        {{
          $t(
            'pages.product_service_questions.edit.form.properties.required.label'
          )
        }}
      </el-checkbox>
    </template>

    <el-form ref="form" :model="form" :rules="rules">
      <el-row :gutter="20">
        <!-- Name -->
        <el-col :sm="8" :md="8" :lg="6">
          <el-form-item
            :label="
              $t(
                'pages.product_service_questions.edit.form.properties.name.label'
              )
            "
            prop="name"
          >
            <el-input id="name" v-model="form.name" />
          </el-form-item>
        </el-col>

        <!-- Question Content -->
        <el-col :sm="16" :md="12" :lg="12">
          <el-form-item
            :label="
              $t(
                'pages.product_service_questions.edit.form.properties.content.label'
              )
            "
            prop="content"
          >
            <el-input id="content" v-model="form.content" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <!-- Answer Validation -->
        <el-col :sm="8" :md="8" :lg="6">
          <el-form-item
            :label="
              $t(
                'pages.product_service_questions.edit.form.properties.answer_validation.label'
              )
            "
            prop="answer_validation"
          >
            <el-select
              id="answer_validation"
              v-model="answerValidation"
              clearable
              class="w-full"
              @change="handleValidationChange"
            >
              <el-option
                v-for="option in answerValidationOptions"
                :key="option.value"
                :value="option.value"
                :label="option.label"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <div v-if="answerValidation">
        <validations-simple-select
          v-if="answerValidation === 'simple_select'"
          v-model="form.answer_validation"
        />
        <validations-number-input-plus-optional-unit-select
          v-if="answerValidation === 'number_input_plus_optional_unit_select'"
          v-model="form.answer_validation"
        />
      </div>
    </el-form>
  </th-wrapper>
</template>

<script>
import ValidationsSimpleSelect from './validations/simple_select'
import ValidationsNumberInputPlusOptionalUnitSelect from './validations/number_input_plus_optional_unit_select'

import th from '@tillhub/javascript-sdk'
import pick from 'just-pick'
import safeGet from 'just-safe-get'
import deepClone from 'clone-deep'
import { isEmptyObject, isEmptyArray } from '@/utils/objects'
import { isEmptyString } from '@/utils/strings'
import { mapGetters } from 'vuex'

function genInitialData() {
  return {
    name: null,
    required: false,
    description: null,
    content: null,
    answer_validation: null
  }
}

export default {
  components: {
    ValidationsSimpleSelect,
    ValidationsNumberInputPlusOptionalUnitSelect
  },

  data() {
    return {
      loading: false,
      form: genInitialData(),
      pristineForm: genInitialData(),
      rules: {
        name: [
          {
            required: true,
            message: this.$t(
              'pages.product_service_questions.edit.form.rules.name.required'
            ),
            trigger: 'blur'
          },
          {
            min: 3,
            max: 128,
            message: this.$t('common.forms.rules.min_max_length', {
              min: 3,
              max: 128
            }),
            trigger: 'blur'
          }
        ],
        description: [
          {
            min: 1,
            max: 4096,
            message: this.$t('common.forms.rules.min_max_length', {
              min: 3,
              max: 128
            }),
            trigger: 'blur'
          }
        ],
        content: [
          {
            required: true,
            message: this.$t(
              'pages.product_service_questions.edit.form.rules.content.required'
            ),
            trigger: 'blur'
          },
          {
            min: 1,
            max: 4096,
            message: this.$t('common.forms.rules.min_max_length', {
              min: 3,
              max: 128
            }),
            trigger: 'blur'
          }
        ]
      },
      pristineanswerValidation: null,
      answerValidation: null,
      answerValidationOptions: [
        {
          label: this.$t(
            'pages.product_service_questions.edit.form.properties.answer_validation.option.checkbox.label'
          ),
          value: 'checkbox'
        },
        {
          label: this.$t(
            'pages.product_service_questions.edit.form.properties.answer_validation.option.free_text_input.label'
          ),
          value: 'free_text_input'
        },
        {
          label: this.$t(
            'pages.product_service_questions.edit.form.properties.answer_validation.option.simple_select.label'
          ),
          value: 'simple_select'
        },
        {
          label: this.$t(
            'pages.product_service_questions.edit.form.properties.answer_validation.option.number_input_plus_optional_unit_select.label'
          ),
          value: 'number_input_plus_optional_unit_select'
        },
        {
          label: this.$t(
            'pages.product_service_questions.edit.form.properties.answer_validation.option.customer_number.label'
          ),
          value: 'customer_number'
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      navigationAfterCreation: 'Config/getNavigationAfterCreation'
    }),

    isNew() {
      return !this.$route.params.id
    },

    questionId() {
      return this.$route.params.id
    }
  },

  mounted() {
    if (!this.isNew) this.fetch(this.questionId)
  },

  methods: {
    async fetch(id) {
      this.loading = true
      try {
        const { data = {} } = await th.productServiceQuestions().get(id)
        if (data.id) {
          this.handleItem(data)
        }
      } catch (err) {
        this.$logException(err, {
          trackError: false,
          message: this.$t('common.error.action.read.single', {
            resource: this.$t(
              'common.resource.product_service_question.singular'
            )
          })
        })
      } finally {
        this.loading = false
      }
    },

    handleItem(item) {
      this.payload = item
      this.form = {
        ...pick(item, Object.keys(this.form))
      }
      this.answerValidation = safeGet(this.form, 'answer_validation.title')
      this.pristineanswerValidation = deepClone(this.answerValidation)
      this.pristineForm = deepClone(this.form)
    },

    handleReset() {
      this.answerValidation = deepClone(this.pristineanswerValidation)
      this.form = deepClone(this.pristineForm)
    },

    async handleSubmit() {
      const valid = await this.$refs.form.validate()
      if (!valid) {
        return this.$message({
          type: 'warning',
          title: this.$t('common.forms.warning.invalid_input.title'),
          message: this.$t('common.forms.warning.invalid_input.message')
        })
      }

      if (this.isNew) return this.create()
      this.alter()
    },

    async validate() {
      return new Promise((resolve) => {
        this.$refs.form.validate(resolve)
      })
    },

    async alter() {
      this.loading = true
      try {
        const payload = this.makeHandleableBody(this.form)
        const { data = {} } = await th
          .productServiceQuestions()
          .put(this.questionId, payload)

        // Show success
        this.$message({
          type: 'success',
          message: this.$t('common.success.action.update.single', {
            resource: this.$t(
              'common.resource.product_service_question.singular'
            )
          })
        })

        // Update form
        this.handleItem(data)
      } catch (err) {
        this.$logException(err, {
          message: this.$t('common.error.action.update.single', {
            resource: this.$t(
              'common.resource.product_service_question.singular'
            )
          })
        })
      } finally {
        this.loading = false
      }
    },

    async create() {
      this.loading = true
      try {
        const payload = this.makeHandleableBody(this.form)
        const { data = {} } = await th.productServiceQuestions().create(payload)

        // Show success
        this.$message({
          type: 'success',
          message: this.$t('common.success.action.create.single', {
            resource: this.$t(
              'common.resource.product_service_question.singular'
            )
          })
        })

        // Update form
        this.handleItem(data)

        // Navigate
        if (this.navigationAfterCreation === 'edit') {
          this.$router.push({
            name: 'products-service-questions-edit',
            params: { id: data.id }
          })
        } else {
          this.$router.push({ name: 'products-service-questions-list' })
        }
      } catch (err) {
        this.$logException(err, {
          message: this.$t('common.error.action.create.single', {
            resource: this.$t(
              'common.resource.product_service_question.singular'
            )
          })
        })
      } finally {
        this.$ampli.eventWithBaseProps('productCheckoutQuestionCreated')
        this.loading = false
      }
    },

    async handleDelete() {
      const confirm = await this.$askToDelete(this.form.name || this.form.id)
      if (confirm) this.deleteResource()
    },

    async deleteResource() {
      this.loading = true
      try {
        await th.productServiceQuestions().delete(this.questionId)
        this.$message({
          type: 'success',
          message: this.$t('common.success.action.delete.single', {
            resource: this.$t(
              'common.resource.product_service_question.singular'
            )
          })
        })
        this.handleReset()
        this.$router.push({ name: 'products-service-questions-list' })
      } catch (err) {
        this.$logException(err, {
          message: this.$t('common.error.action.delete.single', {
            resource: this.$t(
              'common.resource.product_service_question.singular'
            )
          })
        })
      } finally {
        this.loading = false
      }
    },

    handleValidationChange(v) {
      if (!v) {
        this.form.answer_validation = null
        return
      }

      const templates = new Map([
        [
          'number_input_plus_optional_unit_select',
          {
            type: 'object',
            title: 'number_input_plus_optional_unit_select',
            required: ['content'],
            properties: {
              content: {
                type: 'number',
                max: undefined,
                min: undefined
              },
              unit: {
                type: 'string',
                enum: [null]
              }
            }
          }
        ],
        [
          'simple_select',
          {
            type: 'object',
            title: 'simple_select',
            required: ['content'],
            properties: {
              content: {
                type: 'string',
                enum: [null]
              }
            }
          }
        ],
        [
          'free_text_input',
          {
            type: 'object',
            title: 'free_text_input',
            required: ['content'],
            properties: {
              content: {
                type: 'string'
              }
            }
          }
        ],
        [
          'checkbox',
          {
            type: 'object',
            title: 'checkbox',
            required: ['content'],
            properties: {
              content: {
                type: 'boolean',
                default: false
              }
            }
          }
        ],
        [
          'customer_number',
          {
            type: 'object',
            title: 'customer_number',
            required: ['content'],
            properties: {
              content: {
                type: 'string'
              }
            }
          }
        ]
      ])

      this.form.answer_validation = templates.get(v)
    },

    makeHandleableBody(form) {
      const result = deepClone(form)
      // replace empty objects, emtpy arrays and empty strings with null
      Object.keys(form).forEach((key) => {
        const isEmpty =
          isEmptyObject(result[key]) ||
          isEmptyArray(result[key]) ||
          isEmptyString(result[key])
        result[key] = isEmpty ? null : result[key]
      })
      return result
    }
  }
}
</script>
