<template>
  <div class="mb-4">
    <!-- Display text -->
    <th-input-title
      :title="
        $t(
          'pages.product_service_questions.edit.form.properties.answer_validation.option.simple_select.title'
        )
      "
    />

    <el-row
      v-for="(option, index) in options"
      :key="index"
      :gutter="20"
      class="mb-3"
    >
      <!-- Value -->
      <el-col :xs="18" :sm="12" :md="8" :lg="6">
        <el-input
          :model-value="option"
          data-testid="option-input"
          clearable
          @input="(v) => handleOptionInput(v, index)"
        />
      </el-col>

      <el-col :xs="6" :sm="12" :md="16" :lg="18">
        <!-- Add -->
        <el-button
          v-if="options.length - 1 === index"
          data-testid="add-button"
          plain
          icon="Plus"
          class="el-button--primary-icon"
          @click="addNew"
        />

        <!-- Delete -->
        <el-button
          v-else
          data-testid="delete-button"
          plain
          icon="Delete"
          class="el-button--text-icon"
          @click="removeItem(index)"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import safeGet from 'just-safe-get'
import safeSet from 'just-safe-set'

export default {
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },

  computed: {
    options: {
      get() {
        return this.parseOptions(this.modelValue)
      },
      set(v) {
        // no-op
      }
    }
  },

  methods: {
    parseOptions(obj) {
      const arr = []
      const _enum = safeGet(obj, 'properties.content.enum')
      if (Array.isArray(_enum)) return _enum
      return arr
    },

    handleOptionInput(v, index) {
      const arr = [...this.options]
      arr[index] = v
      const result = {
        ...this.modelValue
      }
      safeSet(result, 'properties.content.enum', arr)
      this.$emit('update:modelValue', result)
    },

    addNew() {
      const options = [...this.options, null]
      const result = {
        ...this.modelValue
      }
      safeSet(result, 'properties.content.enum', options)
      this.$emit('update:modelValue', result)
    },

    removeItem(index) {
      this.options.splice(index, 1)
      const result = {
        ...this.modelValue
      }
      safeSet(result, 'properties.content.enum', this.options)
      this.$emit('update:modelValue', result)
    }
  }
}
</script>
