<template>
  <th-page-wrapper>
    <th-datatable
      ref="table"
      :headers="headers"
      do-route
      do-route-filters
      show-search-filter
      :show-operations="false"
      no-meta-check
      route-base="/products/product_service_questions"
      resource="productServiceQuestions"
      :resource-query="{ query: { deleted: false } }"
      :buttons="computedButtons"
      @loading-error="handleLoadingError"
    />
  </th-page-wrapper>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$t('pages.product_service_questions.all.title')
    }
  },

  data() {
    return {
      headers: [
        {
          field: 'name',
          label: this.$t('common.headers.name.title'),
          fallback: '-',
          truncate: true
        },
        {
          field: 'content',
          label: this.$t('pages.product_service_questions.all.headers.content'),
          fallback: '-',
          truncate: true
        }
      ],
      buttons: [
        {
          type: 'create',
          scopes: ['products:service_questions:create']
        }
      ]
    }
  },

  computed: {
    computedButtons() {
      return this.buttons.filter((b) =>
        b.scopes ? this.$checkPermissions({ scopes: b.scopes }) : true
      )
    }
  },

  methods: {
    refresh() {
      this.$refs.table.refresh()
    },

    handleLoadingError(err) {
      this.$logException(err, {
        trackError: false,
        message: this.$t('common.error.action.read.multiple', {
          resources: this.$t('pages.product_service_questions.title')
        })
      })
    }
  }
}
</script>
